.container {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  width: 280px;
}

.inputWrapper {
  position: relative;
}

.hiddenLabel {
  margin-bottom: 4px;
  visibility: hidden;
}
.container {
  label {
    position: absolute;
    bottom: 0;
    padding-left: 16px;
    padding-bottom: 4px;
    transition: all 0.2s ease;

    &.focus {
      padding-bottom: 0;
      margin-bottom: 0;
      bottom: calc(100%);
      transform: translateY(100%);
    }
  }

  label.focus,
  .hiddenLabel {
    font-size: 12px;
    line-height: 15px;
    color: var(--grey);
    padding-left: 16px;
    display: block;
  }
}

.input {
  width: 100%;
  padding: 4px 16px;
  border: none;
  border-bottom: 1px var(--charcoal) solid;

  &:focus {
    outline: none;
  }
}

.errorWrapper {
  font-size: 12px;
  line-height: 15px;
  color: var(--red-error);
  padding-left: 16px;
  margin-top: 6px;
  margin-bottom: 3px;
  min-height: 15px;
}
