@import '~@styles/mixins';

.container {
  display: flex;
  & > * {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }

  @include mobile() {
    flex-direction: column;
    & > * {
      margin-right: 0;
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.tabSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;

  h1 {
    padding: 32px 0 16px;
    border-bottom: 1px solid var(--light-grey);
    margin-bottom: 16px;
  }

  @include mobile() {
    position: static;
  }
}

.tabList {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  & > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  padding: 0;
  a {
    color: unset;
    text-decoration: none;
  }
}

.navItem {
  cursor: pointer;
  transition: all 0.2s;
  font-size: 16px;

  &:hover {
    color: var(--green-hover);
  }

  &.active {
    color: var(--green);
    font-weight: 600;
  }
}

.tabContent {
  width: 100%;
  max-width: 640px;
}

.tabPane {
  margin-top: 32px;
  display: none;
  &.show {
    display: block;
  }
}
