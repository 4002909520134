@import '~@styles/mixins';

.container {
  height: 100vh;
  background-color: var(--green);
  position: relative;
  padding: 40px;

  @include mobile() {
    padding: 20px;
  }
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 40px;
  background-color: var(--white);
  width: 60%;

  @include mobile() {
    padding: 20px;
  }
}

.graphic {
  width: 100%;
  height: 100%;
  background-image: url('~@images/graphics_bg.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.hugeText {
  font-size: 120px;
  line-height: 120px;
  font-weight: 700;

  @include tablet() {
    font-size: 110px;
    line-height: 110px;
  }

  @include mobile() {
    font-size: 70px;
    line-height: 70px;
  }
}

.bigText {
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  padding-bottom: 10px;

  @include tablet() {
    font-size: 28px;
    line-height: 28px;
  }
}

.normalText {
  padding-bottom: 20px;
}
