@import '~@styles/mixins';

.container {
  display: flex;
  height: 88px;
  background-color: var(--navy);
  padding: 0 48px;
  color: var(--white);
  font-weight: 600;
  align-items: center;
  & > * {
    margin-right: 48px;
    &:last-child {
      margin-right: 0;
    }
  }

  @include mobile() {
    padding: 0 20px;
  }
}

.logo {
  width: 132px;
  height: 56px;
  background-image: url('~@images/ICA-logo-white.png');
}

.wrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  & > * {
    margin-right: 48px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.navList {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  & > * {
    margin-right: 48px;
    &:last-child {
      margin-right: 0;
    }
  }
  padding: 0;
}

.navItem {
  color: var(--white);
  text-decoration: none;
  font-weight: 600;
  transition: all ease 0.2s;
  position: relative;

  &:hover {
    color: var(--light-grey-1);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 2px;
    background: var(--white);
    transition: width 0.2s;
  }

  &.active {
    color: var(--white);
    &::after {
      width: 100%;
    }
  }
}

.logoutButton {
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover {
    color: var(--light-grey-1);
  }
}

.menuIcon {
  display: none;
}

.navicon {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;

  &::before,
  &::after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  &::before {
    top: 5px;
  }

  &::after {
    top: -5px;
  }
}

.navicon.show {
  background: transparent;

  &::before {
    top: 0;
    transform: rotate(-45deg);
  }

  &::after {
    top: 0;
    transform: rotate(45deg);
  }
}

@include tablet() {
  .container {
    position: relative;
    justify-content: space-between;
    z-index: 9;
    & > * {
      margin-right: 0;
    }
  }

  .wrapper {
    position: absolute;
    max-height: 0px;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    top: 100%;
    left: 0;
    background-color: var(--navy);
    width: 100%;
    padding: 0 48px;
  }

  .menuIcon {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
  }

  .wrapper,
  .navList {
    display: flex;
    flex-direction: column;
    & > * {
      margin-right: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .wrapper.show {
    max-height: 250px;
  }

  .logoutButton {
    text-align: center;
    padding-bottom: 20px;
  }
}
