@import '~@styles/mixins';

.container {
  height: 100%;
  background-color: var(--green);
  display: flex;

  @include tablet() {
    flex-direction: column-reverse;
    height: auto;
  }

  h1 {
    text-align: center;
  }
}

.graphicContainer {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include tablet() {
    min-height: 45vh;
    width: 100%;
    padding: 16px;
  }
}

.graphic {
  margin-left: 44px;
  width: 100%;
  height: 89%;
  background-image: url('~@images/graphics_bg.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;

  @include tablet() {
    flex: 1;
    margin-left: 0;
    background-position-x: center;
    background-size: contain;
  }
}

.loginContainer {
  width: 50%;
  min-width: 404px;
  min-height: 500px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  & > * {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include tablet() {
    width: 100%;
  }

  @include mobile() {
    min-width: 320px;
  }
}

.logo {
  width: 190px;
  height: 80px;
  background-image: url('~@images/ICA_logo_green.png');
}

.sent {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  & > * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.resend {
  color: var(--grey);
  font-weight: 600;
  height: 44px;
  display: flex;
  align-items: center;
}

.issueText {
  text-align: center;
}
