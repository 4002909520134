@import '~@styles/mixins';

:global {
  body {
    .rc-slider {
      width: 330px;
      height: 20px;
      padding: 6px 0;

      @include mobile() {
        width: 100%;
      }
    }
    .rc-slider-rail {
      height: 8px;
      border-radius: 10px;
      background-color: var(--light-grey-2);
    }
    .rc-slider-track {
      height: 8px;
      border-radius: 10px;
      background-color: var(--green);
    }
    .rc-slider-step {
      height: 8px;
    }
    .rc-slider-dot {
      border: none;
      background-color: var(--grey);
      width: 4px;
      height: 4px;
      bottom: 2px;
    }
    .rc-slider-dot-active {
      background-color: var(--green);
    }
    .rc-slider-handle {
      width: 20px;
      height: 20px;
      border: 4px solid var(--green);
      margin-top: -6px;

      &:hover,
      &:active {
        border-color: var(--green);
        box-shadow: none;
      }
    }
    .rc-slider-handle-click-focused {
      border-color: var(--green);

      &:focus {
        border-color: var(--green);
      }
    }
    .rc-slider-mark {
      top: 23px;
    }
    .rc-slider-mark-text,
    .rc-slider-mark-text-active {
      font-size: 14px;
      line-height: 20px;
      color: var(--charcoal);
    }
    .rc-slider-mark-text:first-child {
      margin-left: 3px;
    }
  }
}

.slider {
  margin-bottom: 26px;
}

.label {
  font-size: 12px;
  line-height: 16px;
  color: var(--grey-2);
  margin-bottom: 10px;
}
