.container {
  display: flex;
  align-items: flex-start;
  input[type='checkbox'] {
    appearance: none;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    &:checked + .checkbox {
      border: 2px solid var(--green);
      background: var(--green);
      .checkIcon {
        transform: rotate(0) scale(1);
      }
    }
  }
}
.checkbox {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--charcoal);
  width: 14px;
  height: 14px;
  margin: 3px 8px 3px 4px;
  transition: all ease 0.2s;
}
.checkIcon {
  transition: all ease 0.2s;
  transform: rotate(90deg) scale(0);
}
