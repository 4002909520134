@import '~@styles/mixins';

.container {
  padding: 32px 48px;

  @include mobile() {
    padding: 16px 20px;
  }
}

.text {
  max-width: 600px;
  margin-top: 16px;
}

.row {
  display: flex;
  align-items: center;
  & > * {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
  margin-top: 32px;

  @include tablet() {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      margin-right: 0;
    }
  }
}

.slider {
  margin-top: 11px;
}

button.resultButton {
  margin-top: 32px;
}
