@import '~@styles/mixins';

.DayPicker-Weekday,
.dayItem {
  display: inline-flex;
  padding: 0;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: var(--charcoal);

  // @include mobile() {
  //   width: 14px;
  //   height: 14px;
  // }
}
.DayPicker-Day--outside .dayItem,
.DayPicker-Day--disabled .dayItem {
  color: var(--grey);
  cursor: default;
  opacity: 0.5;
}

.InputFromTo {
  color: var(--charcoal);
  input {
    width: 94px;
    border: none;
    padding: 0;
    outline: none;
    color: var(--charcoal);
  }
  &.hideInput {
    opacity: 0;
    input {
      width: 0;
    }
  }
  .DayPicker-Day--selected {
    &.DayPicker-Day--start {
      background: linear-gradient(
        90deg,
        var(--transparent) 75%,
        var(--green-02) 25%
      );
    }
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: unset;
      .dayItem {
        background-color: var(--green);
        color: var(--white);
      }
    }
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: unset;
    }
    &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: var(--green-02);
      .dayItem {
        background-color: var(--transparent);
        color: var(--charcoal);
      }
    }
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--end {
      background: linear-gradient(
        90deg,
        var(--green-02) 75%,
        var(--transparent) 25%
      );
      &.DayPicker-Day--start {
        background: unset;
      }
    }
  }
}

.InputMultiple {
  .DayPicker-Day--selected {
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: unset;
      .dayItem {
        background-color: var(--green);
        color: var(--white);
      }
    }
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
      + .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: var(--green-02);
    }
  }
}

.DayPicker {
  &:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day {
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: unset;
      }
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
        .dayItem {
          &:hover {
            background-color: var(--green-02);
          }
        }
      }
    }
  }
}
.DayPicker-Day {
  border-radius: 0;
}
.DayPickerInput-OverlayWrapper {
  position: initial;
}
.DayPickerInput-Overlay {
  top: calc(100% + 8px);
  overflow: hidden;
  cursor: default;
  background-color: var(--light-grey-1);
}
.DayPicker-wrapper {
  padding-bottom: 0;
}
.DayPicker-Month {
  display: block;
  margin: 15px;
}
.deselectWrapper {
  text-align: right;
  color: var(--grey);
  font-weight: 600;
  padding: 8px 0;
  border-top: 1px solid var(--grey);
  margin: 0 16px;

  span {
    cursor: pointer;
  }
  .active {
    color: var(--green);
  }
}
.monthYearLabel {
  margin-top: 10px;
  text-align: center;
  font-weight: 700;
  color: var(--charcoal);
}
.DayPicker-Weekdays {
  display: block;
  margin-top: 20px;
  margin-bottom: 12px;
}
.DayPicker-WeekdaysRow {
  display: flex;
  justify-content: space-between;
  font-weight: 600;

  & > * {
    margin-right: 29.5px;
    &:last-child {
      margin-right: 0;
    }
  }

  @include mobile() {
    & > * {
      margin-right: 14px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.dayItem {
  font-size: 12px;
  line-height: 13px;
  border-radius: 12px;
  transition: all ease 0.2s;
}
.DayPicker-Body {
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.DayPicker-Week {
  display: block;
  .DayPicker-Day--selected {
    &:first-child {
      border-radius: 12px 0 0 12px;
    }
    &:last-child {
      border-radius: 0 12px 12px 0;
    }
  }
}
.DayPicker-Day {
  display: inline-block;
  padding: 0 0 0 29.5px;
  transition: all ease 0.2s;
  &:first-child {
    padding-left: 0;
  }

  @include mobile() {
    padding-left: 14px;
  }
}
.DayPicker-Day--today {
  .dayItem {
    color: var(--charcoal);
    font-weight: normal;
    background-color: var(--green-02);
    border-radius: 12px;
  }
  &.DayPicker-Day--selected {
    .dayItem {
      border-radius: 12px;
    }
  }
}
.navBar {
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  button {
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    background-color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      transform: rotateY(180deg);
    }
  }
}

.DatePickerICA {
  display: inline-flex;
  flex-direction: column;
  width: 280px;
  z-index: 2;

  .errorWrapper {
    font-size: 12px;
    line-height: 15px;
    color: var(--red-error);
    padding-left: 16px;
    margin-top: 6px;
    margin-bottom: 3px;
    min-height: 15px;
  }
  .buttonWrapper {
    position: relative;
    width: 100%;
    padding: 4px 0 4px 16px;
    cursor: pointer;
    background-color: var(--white);
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    border-bottom: 1px var(--charcoal) solid;
  }
  .fixedLabel {
    margin-right: 7px;
    display: inline-flex;
  }
  .selectedValue {
    flex: 1;
  }
  .arrowWrapper {
    transition: all ease 0.2s;
    &.arrowUp {
      transform: rotate(180deg);
    }
  }
  .defaultLabel {
    position: absolute;
    bottom: 0;
    padding-bottom: 4px;
    transition: all 0.2s ease;

    &.focus {
      padding-bottom: 0;
      margin-bottom: 0;
      bottom: calc(100%);
    }
  }
  .hiddenLabel {
    visibility: hidden;
  }
  .defaultLabel.focus,
  .hiddenLabel {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey);
    display: block;
  }
}

.InputMultiple {
  input {
    width: 100%;
    border: none;
    padding: 0;
    outline: none;
  }
  .DayPickerInput {
    display: block;
  }
}

.DatePickerICA.disabled {
  .buttonWrapper,
  input {
    background: var(--grey-border-3);
    color: var(--grey);
  }
}
