@import '~@styles/mixins';

.container {
  padding: 32px 48px 44px;
  background-color: var(--grey-bg);

  @include mobile() {
    padding: 16px 20px;
  }
}

.content {
  display: flex;
  margin-top: 32px;
  & > * {
    margin-right: 28px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.left {
  flex: 508px;
  max-width: 508px;
}

.phoOptions,
.siteWrapper {
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  flex: 1;
}

.phoList {
  height: 434px;
  background-color: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.siteList {
  margin-top: 16px;
  height: 330px;
  background-color: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

button.sendButton {
  margin-top: 20px;
}

.right {
  flex: 808px;
  background-color: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  & > * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.numberBadge {
  color: var(--white);
  font-weight: 600;
  padding: 0 9px;
  border-radius: 10px;
  background-color: var(--orange);

  &.greyBadge {
    background-color: var(--grey);
  }
}

.mapWrapper {
  flex: 1;
  width: 100%;
  min-height: 500px;
}

.selectAllText {
  font-weight: 600;
  color: var(--green);
}

.phoItem,
.siteItem {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--light-grey);
  &:last-child {
    border-bottom: none;
  }
}

.phoContent {
  overflow: hidden;
}

.phoName {
  color: var(--blue-link);
  text-decoration: underline;
}

.phoDate,
.greyText {
  color: var(--grey);
}

.outerTitle {
  font-weight: 600;
  margin-top: 16px;
}

.phoType {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.simpleBar {
  height: 100%;
}

.phoScroll,
.siteScroll {
  flex: 1;
  overflow: hidden;
}

@include desktop-small() {
  .content {
    flex-wrap: wrap;
    & > * {
      margin-right: 0;
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
