.container {
  margin: 0 auto;
  width: 100%;
  max-width: 664px;
  text-align: left;
}

.item {
  border: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  &:not(:first-child) {
    border-top: none;
  }
}

.title {
  padding: 16px;
  cursor: pointer;
}

.contentWrapper {
  max-height: 0px;
  transition: max-height 0.3s ease-in;
  overflow: hidden;

  .content {
    border-top: none;
  }
}

.content {
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  p {
    margin: 0;
  }
  ol {
    margin: 0;
  }
}

.toggleButton {
  flex-shrink: 0;
  height: 16px;
  position: relative;
  width: 16px;
}

.plus {
  position: absolute;
  top: 1px;
  left: 1px;
  display: flex;
  transition: all 0.3s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}

.minus {
  position: absolute;
  top: calc(50% - 2.5px);
  display: flex;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg);
  opacity: 0;
}

.active {
  .plus {
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
  .minus {
    transition: all 0.3s ease-in-out;
    transform: rotate(180deg);
    opacity: 1;
  }
}
