.container {
  padding: 12px;
  margin: 0;
  cursor: pointer;
  font-weight: 600;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--green-hover);
  }

  &.disabled {
    pointer-events: none;
  }
}

.primary {
  background-color: var(--green);
  color: var(--white);
  border: none;
}

.outlined {
  padding: 10px;
  background-color: var(--white);
  color: var(--green);
  border: 2px solid var(--green);

  &:hover {
    color: var(--white);
    background-color: var(--green);
  }

  &.disabled {
    color: var(--grey-2);
    border: 2px solid var(--grey-2);
  }
}
