.container {
  padding: 32px 48px;
  text-align: center;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    margin-bottom: 8px;
  }
}
