@import '~@styles/mixins';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: var(--transparent-black);
  justify-content: center;
  align-items: center;
  display: none;
  &.show {
    display: flex;
    padding-right: 17px;

    @include mobile() {
      padding: 20px;
    }
  }
}

.wrapper {
  background-color: var(--white);
  padding: 44px 20px 20px;
  position: relative;
  text-align: center;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.header {
  margin-bottom: 24px;
  color: var(--black);
}

.body {
  margin-bottom: 24px;
}

.footer {
  display: flex;
  justify-content: center;
  & > * {
    margin-right: 36px;
    &:last-child {
      margin-right: 0;
    }
  }
}
