:root {
  --white: #fff;
  --black: #000;
  --navy: #0a334d;
  --green: #2a8164;
  --green-02: #2a836533;
  --green-hover: #379777;
  --orange: #d2440f;
  --charcoal: #2f2f39;
  --grey: #696969;
  --grey-2: #757575;
  --grey-bg: #f8f8f8;
  --light-grey: #ccc7ba;
  --light-grey-1: #d8d8d8;
  --light-grey-2: #e4e4e4;
  --light-grey-3: #c4c4c4;
  --red-error: #e4100e;
  --blue-link: #1255cc;
  --transparent: rgba(255, 255, 255, 0);
  --transparent-black: rgba(0, 0, 0, 0.4);
}
