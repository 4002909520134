@import '~@styles/mixins';

.container {
  padding-left: 160px;
  padding-bottom: 44px;
  img {
    width: 100%;
  }
  h2 {
    color: var(--green);
  }
  h4 {
    margin-bottom: 8px;
    & + p {
      margin-top: 6px;
    }
  }

  @include desktop-small() {
    padding-left: 48px;
    padding-right: 48px;
  }

  @include mobile() {
    padding-left: 20px;
    padding-right: 20px;
  }
}

:global {
  #resultsView {
    table:nth-of-type(1) {
      table-layout: fixed;
      width: 100%;
    }

    table:nth-of-type(2) {
      border-collapse: collapse;
      th,
      td {
        padding: 4px;
        border: none;
      }

      thead {
        border-top: 1px solid var(--green);
        border-bottom: 1px solid var(--green);
        th {
          color: var(--green);
        }
      }

      tbody {
        tr:nth-child(2n) {
          background-color: var(--green-02);
        }
      }
    }
  }

  #report {
    table {
      table-layout: fixed;
      width: 100%;
    }
  }
}
