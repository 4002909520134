.container {
  width: 280px;
}

.inputWrapper {
  position: relative;
}

.hiddenLabel {
  margin-bottom: 4px;
  visibility: hidden;
}
.container {
  label {
    position: absolute;
    bottom: 0;
    padding-left: 16px;
    padding-bottom: 4px;
    transition: all 0.2s ease;
    z-index: 1;

    &.focus {
      padding-bottom: 0;
      margin-bottom: 0;
      bottom: calc(100%);
      transform: translateY(100%);
    }
  }

  label.focus,
  .hiddenLabel {
    font-size: 12px;
    line-height: 15px;
    color: var(--grey);
    padding-left: 16px;
    display: block;
  }
}

.input {
  resize: none;
  vertical-align: top;
  height: 29px;
  width: 100%;
  overflow: hidden;
  padding: 4px 20px 4px 16px;
  border: none;
  border-bottom: 1px var(--charcoal) solid;

  &:focus {
    outline: none;
  }
}

.errorWrapper {
  font-size: 12px;
  line-height: 15px;
  color: var(--red-error);
  padding-left: 16px;
  margin-top: 6px;
  margin-bottom: 3px;
  min-height: 15px;
}

.dropdownList {
  z-index: 99;
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  background-color: var(--light-grey-1);
  padding: 4px 0;
}

.dropdownItem {
  padding: 8px 12px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--green-02);
  }
}

.textWrapper {
  position: relative;
}

.loadingWrapper {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.loading {
  border: 2px solid var(--light-grey);
  animation: spin 0.5s linear infinite;
  border-top: 2px solid var(--charcoal);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
