.map {
  width: 100%;
  height: 100%;

  :global {
    .mapboxgl-ctrl-top-left .mapboxgl-ctrl,
    .maplibregl-ctrl-top-left .maplibregl-ctrl {
      margin: 16px 0 0 16px;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .mapboxgl-ctrl-group,
    .maplibregl-ctrl-group {
      border-radius: 10px;
    }

    .mapboxgl-ctrl-group button,
    .maplibregl-ctrl-group button {
      width: 40px;
      height: 40px;

      &.maplibregl-ctrl-zoom-in,
      &.maplibregl-ctrl-zoom-out {
        height: 38.5px;
      }
    }

    .mapboxgl-ctrl-group button + button,
    .maplibregl-ctrl-group button + button {
      padding: 0 3px;
      border-top: none;
      .maplibregl-ctrl-icon.mapboxgl-ctrl-icon {
        border-top: 1px solid #d0d0d0;
      }
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon,
    .maplibregl-ctrl button.maplibregl-ctrl-fullscreen .maplibregl-ctrl-icon {
      background-image: url('~@images/expand.svg');
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
    .maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon {
      background-image: url('~@images/small-plus.svg');
    }

    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,
    .maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
      background-image: url('~@images/small-minus.svg');
    }

    .maplibregl-popup-content.mapboxgl-popup-content {
      border-radius: 10px;
      padding: 16px;
      box-shadow: none;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
    }

    .map-popup {
      word-break: break-all;
      & > * {
        margin-top: 8px;
        margin-bottom: 0;
      }

      .grey-text {
        margin-top: 16px;
        color: var(--grey);
      }
    }

    .distance-badge {
      border-radius: 99px;
      padding: 0 9px;
      background-color: var(--green);
      color: var(--white);
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }

    .centerMarker {
      opacity: 0.7;
    }
  }
}
