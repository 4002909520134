@mixin desktop-small() {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin tablet() {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: 425px) {
    @content;
  }
}
