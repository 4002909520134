@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import '~@styles/colors';
@import '~@styles/mixins';

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--charcoal);
  max-width: 1440px;

  .simplebar-track.simplebar-vertical {
    width: 8px;
  }
  .simplebar-scrollbar:before {
    left: 0px;
    right: 4px;
    background: var(--light-grey-3);
  }
}

input,
button,
textarea {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 20px;
}

a {
  color: var(--blue-link);
}

h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  margin: 0;

  @include mobile() {
    font-size: 30px;
    line-height: 34px;
  }
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin: 0;

  @include mobile() {
    font-size: 22px;
    line-height: 34px;
  }
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  @include mobile() {
    font-size: 18px;
    line-height: 24px;
  }
}

.mapboxgl-map,
.maplibregl-map {
  font-family: 'Inter', sans-serif;
}
